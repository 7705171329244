import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { PropTypes } from 'prop-types'

/** Displays details about the email, and allows the user to check the box, indicating they wish to send it */
const EmailDesc = ({ checked, setChecked, title, description }) => {
    return (
        <Grid container alignItems="center" item xs={12}>
            <Grid container justify="center" item xs={2}>
                <Grid item>
                    <Checkbox checked={checked} onChange={() => setChecked()} />
                </Grid>
            </Grid>
            <Grid container item xs={10}>
                <Grid container item xs={12}>
                    <Grid item>
                        <Typography variant="h5">{title}</Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" item xs={12}>
                    <Grid item>
                        <Typography variant="subtitle2">
                            {description}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default EmailDesc

EmailDesc.propTypes = {
    checked: PropTypes.bool.isRequired,
    setChecked: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string
}
