import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Papa from 'papaparse'
import { validateEmailFormat } from '../shared/validate'
import { Typography } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

function isNum(value) {
    return value !== null && value !== '' && !isNaN(Number(value))
}

function CSVReader({ data, setData }) {
    const [error, setError] = useState()

    const handleFileUpload = (event) => {
        const file = event.target.files[0]
        Papa.parse(file, {
            complete: (result) => {
                let localData = []
                // Lets make sure the format matches what we're expecting
                for (let i = 0; i < result.data.length; i++) {
                    let { cid, email } = result.data[i]
                    cid = cid?.trim()
                    email = email?.trim()

                    if (!cid && !email) {
                        // true if both are: null, undefined, NaN, empty, 0, or false.
                        // Blank line, or wrong header, or ^.
                        continue
                    }
                    if (!isNum(cid) || cid <= 0) {
                        setError(`found an invalid CID on row ${i}: ${cid}`)
                        setData([])
                        return
                    }
                    if (!validateEmailFormat(email?.trim())) {
                        setError(`found an invalid email on row ${i}: ${email}`)
                        setData([])
                        return
                    }
                    localData.push({ cid, email })
                }
                setData(localData)
                if (localData.length === 0) {
                    setError(
                        'No valid data found. Are your headers "cid" and "email"?'
                    )
                    return
                }
                setError()
            },
            header: true
        })
    }

    return (
        <Grid item container xs={12}>
            <Grid item>
                <input type="file" accept=".csv" onChange={handleFileUpload} />
            </Grid>
            <Typography color="error">{error}</Typography>
            {data.length > 0 && (
                <>
                    <Grid item>
                        <div style={styles.scrollBox}>
                            <table>
                                <tbody>
                                    {data.map((row, rowIndex) => (
                                        <tr key={rowIndex}>
                                            <td>{row.cid}</td>
                                            <td>{row.email}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            <b>
                                This will send this email to {data.length}{' '}
                                dealers
                            </b>
                        </Typography>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
CSVReader.propTypes = {
    data: PropTypes.isRequired,
    setData: PropTypes.func.isRequired
}

const styles = {
    scrollBox: {
        maxHeight: '300px', // Set your desired height
        overflowY: 'auto',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        marginTop: '10px' // Optional: add some space between the input and table
    }
}
export default CSVReader
