import React, { useState, useEffect, createContext } from 'react'
import PropTypes from 'prop-types'

export const LoginContext = createContext()

// Allow easy resetting of state
const initialLoginInfo = {
    token: '',
    expires: 0
}

/**
 * Provider for the login context
 */
export const LoginProvider = ({ children }) => {
    // Holds bearer token. If no token exists, user is not logged in, to log out simply clear token.
    const [loginInfo, setLoginInfo] = useState(initialLoginInfo)
    // Clear token when session expires
    useEffect(() => {
        // Run every 15 seconds
        const interval = setInterval(() => {
            // Ensure expiations are set before calculating
            if (!loginInfo.expires) {
                return
            }
            // Time now, converted to UNIX
            let timeNow = Math.floor(Date.now() / 1000)
            // Determine if expired
            if (timeNow >= loginInfo.expires) {
                // Yes, reset loginInfo, which causes Router to display Login page
                setLoginInfo(initialLoginInfo)
            }
        }, 15000)
        // Clean up on unmount
        return () => clearInterval(interval)
    })
    return (
        <LoginContext.Provider value={[loginInfo, setLoginInfo]}>
            {children}
        </LoginContext.Provider>
    )
}

LoginProvider.propTypes = {
    children: PropTypes.node
}
