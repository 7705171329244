import React, { useContext } from 'react'
import endpoints from '../api'
import HostedTheme from '../contexts/HostedTheme'
import { LoginContext } from '../contexts/LoginContext'
import Login from '../pages/Login'
import MainWindow from '../components/MainWindow'
import Sections from '../components/Sections'
import { EmailDataProvider } from '../contexts/EmailDataContext'
import { APIClientProvider } from 'react-toolbox/APIClient'

const WrappedApp = () => {
    // check LoginToken
    const [loginInfo] = useContext(LoginContext)
    return (
        <HostedTheme>
            {loginInfo.token ? (
                <APIClientProvider
                    baseURL={process.env.REACT_APP_BACKEND_URL}
                    opts={{
                        headers: {
                            Authorization: `Bearer ${loginInfo.token}`,
                            'Content-Type': 'application/json'
                        }
                    }}
                    endpoints={endpoints}
                >
                    <MainWindow>
                        <EmailDataProvider>
                            <Sections />
                        </EmailDataProvider>
                    </MainWindow>
                </APIClientProvider>
            ) : (
                <Login />
            )}
        </HostedTheme>
    )
}

export default WrappedApp
