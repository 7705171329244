const endpoints = {
    email: {
        send: ({ cid, tos, email, contactname, deadlinedate }) => ({
            path: '/hosted/',
            method: 'POST',
            body: {
                cid,
                tos,
                email,
                contactname,
                deadlinedate
            }
        }),
        // This is the same form as the send request.
        sendBulk: ({ email, recipients }) => ({
            path: '/hosted/bulk',
            method: 'POST',
            body: {
                email,
                recipients // recipients:[]{cid,tos,contactname,deadlinedate}
            }
        })
    }
}

export default endpoints
