import React, { useContext, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import EmailDesc from '../components/EmailDesc'
import { EmailDataContext } from '../contexts/EmailDataContext'
import { Typography } from '@material-ui/core'

const HostedSelect = () => {
    // State / Context
    const [emailData, setEmailData] = useContext(EmailDataContext)
    const [checked, setChecked] = useState({
        overview: false,
        macsetup: false,
        hostedfrazermacsetup: false,
        hostedfrazeriossetup: false,
        hostedfailedcontact: false,
        hostedfrazerwindowsmfasetup: false
    })
    // On first render, retrieve checked state if it exists
    useEffect(() => {
        if (emailData.selectedEmails) {
            setChecked({ ...emailData.selectedEmails })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Update selectedEmails whenever the user makes a selection, and clear any error message.
    useEffect(() => {
        setEmailData({
            ...emailData,
            selectedEmails: checked,
            hosted: {
                ...emailData.hosted,
                selectError: ''
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked])
    return (
        <Grid container justify="center" spacing={0} alignContent="center">
            <Grid container item spacing={3}>
                <EmailDesc
                    checked={checked.overview}
                    setChecked={() =>
                        setChecked({ ...checked, overview: !checked.overview })
                    }
                    title={emailData.hosted.overview.title}
                    description={emailData.hosted.overview.description}
                />
                <EmailDesc
                    checked={checked.macsetup}
                    setChecked={() =>
                        setChecked({ ...checked, macsetup: !checked.macsetup })
                    }
                    title={emailData.hosted.macsetup.title}
                    description={emailData.hosted.macsetup.description}
                />
                <EmailDesc
                    checked={checked.hostedfrazermacsetup}
                    setChecked={() =>
                        setChecked({
                            ...checked,
                            hostedfrazermacsetup: !checked.hostedfrazermacsetup
                        })
                    }
                    title={emailData.hosted.hostedfrazermacsetup.title}
                    description={
                        emailData.hosted.hostedfrazermacsetup.description
                    }
                />
                <EmailDesc
                    checked={checked.hostedfrazeriossetup}
                    setChecked={() =>
                        setChecked({
                            ...checked,
                            hostedfrazeriossetup: !checked.hostedfrazeriossetup
                        })
                    }
                    title={emailData.hosted.hostedfrazeriossetup.title}
                    description={
                        emailData.hosted.hostedfrazeriossetup.description
                    }
                />
                <EmailDesc
                    checked={checked.hostedfailedcontact}
                    setChecked={() =>
                        setChecked({
                            ...checked,
                            hostedfailedcontact: !checked.hostedfailedcontact
                        })
                    }
                    title={emailData.hosted.hostedfailedcontact.title}
                    description={
                        emailData.hosted.hostedfailedcontact.description
                    }
                />
                <EmailDesc
                    checked={checked.hostedfrazerwindowsmfasetup}
                    setChecked={() =>
                        setChecked({
                            ...checked,
                            hostedfrazerwindowsmfasetup: !checked.hostedfrazerwindowsmfasetup
                        })
                    }
                    title={emailData.hosted.hostedfrazerwindowsmfasetup.title}
                    description={
                        emailData.hosted.hostedfrazerwindowsmfasetup.description
                    }
                />
            </Grid>
            <Grid container justify="center" item xs={12}>
                <Grid item>
                    <br />
                    <Typography color="error">
                        {emailData.hosted.selectError}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HostedSelect
