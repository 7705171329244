import React from 'react'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

/**
 * An extremely useful theme that propogates through all the Material UI Components.
 */
const hostedTheme = createMuiTheme({
    palette: {
        common: {
            black: 'rgba(0, 0, 0, 1)',
            white: 'rgba(255, 255, 255, 1)'
        },
        background: {
            paper: 'rgba(255, 255, 255, 1)',
            default: 'rgba(255, 255, 255, 1)'
        },
        primary: {
            light: 'rgba(192, 192, 192, 1)',
            main: 'rgba(52, 52, 52, 1)',
            dark: 'rgba(42, 42, 42, 1)',
            contrastText: 'rgba(229, 229, 229, 1)'
        },
        secondary: {
            light: 'rgba(167, 53, 54, 1)',
            main: 'rgba(167, 53, 54, 1)',
            dark: 'rgba(122, 122, 122, 1)',
            contrastText: 'rgba(255, 255, 255, 1)'
        },
        error: {
            light: 'rgba(252, 197, 21, 1)',
            main: '#ff1100',
            dark: 'rgba(252, 197, 21, 1)',
            contrastText: '#fff'
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.54)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)'
        }
    },
    typography: {
        button: {
            textTransform: 'none'
        },
        fontFamily: ['Open Sans']
    }
})

/**
 * Provider for the hostedTheme
 */
const HostedTheme = ({ children }) => {
    return <ThemeProvider theme={hostedTheme}>{children}</ThemeProvider>
}

export default HostedTheme

HostedTheme.propTypes = {
    children: PropTypes.node
}
