import React, { useContext, useState } from 'react'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Grid from '@material-ui/core/Grid'
import { EmailDataContext } from '../contexts/EmailDataContext'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { sanitize, sanitizeNumber } from '../shared/formatters'
import { validateEmailFormat } from '../shared/validate'
import Checkbox from '@material-ui/core/Checkbox'
import CSVReader from '../components/CSVReader'

const HostedFields = () => {
    // State / Context
    const [emailData, setEmailData] = useContext(EmailDataContext)
    const [newTo, setNewTo] = useState('')
    const [bulk, setBulk] = useState(emailData?.recipients?.length > 0 || false)

    // adds bulk data to the emaildata context.
    const handleAddBulkData = (recipients) => {
        setEmailData((emailData) => ({ ...emailData, recipients }))
    }

    // Handlers
    const addTos = () => {
        // Ensure its in valid format
        if (validateEmailFormat(newTo)) {
            // Valid format, append to array
            let newTos = emailData.tos
            // Append new Email
            newTos.push(newTo)
            // Update context
            setEmailData({
                ...emailData,
                tos: newTos,
                tosError: ''
            })
            // Reset field state
            setNewTo('')
        } else {
            // Invalid format, warn user
            setEmailData({
                ...emailData,
                tosError: 'Invalid Email'
            })
        }
    }
    const removeTo = (to) => {
        // Filter out the matching email
        let newTos = emailData.tos.filter((email) => email !== to)
        // Update context
        setEmailData({
            ...emailData,
            tos: newTos,
            tosError: ''
        })
    }

    return bulk ? (
        <Grid container justify="center" alignContent="center">
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid container justify="flex-end" item xs={4}>
                                <Grid item>
                                    <Typography>
                                        <b>Bulk Send:</b>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Checkbox
                                        checked={bulk}
                                        onChange={() => setBulk((b) => !b)}
                                    ></Checkbox>
                                </Grid>
                            </Grid>
                            <Grid item xs={7}>
                                <CSVReader
                                    data={emailData.recipients}
                                    setData={handleAddBulkData}
                                />
                            </Grid>
                        </Grid>
                    </>
                </Grid>
            </Grid>
        </Grid>
    ) : (
        <Grid container justify="center" alignContent="center">
            <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                    {emailData.selectedEmails.hostedfrazerwindowsmfasetup &&
                        !emailData.selectedEmails.overview &&
                        !emailData.selectedEmails.hostedfailedcontact &&
                        !emailData.selectedEmails.hostedfrazeriossetup &&
                        !emailData.selectedEmails.hostedfrazermacsetup &&
                        !emailData.selectedEmails.macsetup && (
                            <>
                                <Grid
                                    container
                                    item
                                    xs={12}
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid
                                        container
                                        justify="flex-end"
                                        item
                                        xs={3}
                                    >
                                        <Grid item>
                                            <Typography>
                                                <b>Bulk Send:</b>
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <Checkbox
                                            checked={bulk}
                                            onChange={() => setBulk((b) => !b)}
                                        ></Checkbox>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    <Grid container justify="flex-end" item xs={3}>
                        <Grid item>
                            <Typography>
                                <b>CID:</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={9}>
                        <TextField
                            error={!!emailData.cidError}
                            label={emailData.cidError}
                            value={emailData.cid}
                            onChange={(e) =>
                                setEmailData({
                                    ...emailData,
                                    cid: sanitizeNumber(e.target.value),
                                    cidError: ''
                                })
                            }
                            variant="outlined"
                            size="small"
                            placeholder="700"
                        />
                    </Grid>
                </Grid>
                <Grid container item xs={12} alignItems="center" spacing={2}>
                    <Grid container justify="flex-end" item xs={3}>
                        <Grid item>
                            <Typography>
                                <b>Recipient Email:</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={7}>
                        <TextField
                            error={!!emailData.tosError}
                            label={emailData.tosError}
                            value={newTo}
                            onChange={(e) => {
                                setNewTo(sanitize(e.target.value))
                                setEmailData({ ...emailData, tosError: '' })
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    addTos()
                                }
                            }}
                            placeholder="firstlast@domain.com"
                            variant="outlined"
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Button
                            variant="contained"
                            size="small"
                            onClick={() => addTos()}
                        >
                            Add
                        </Button>
                    </Grid>
                </Grid>
                {emailData.tos && emailData.tos.length > 0 && (
                    <Grid container item xs={12} spacing={2}>
                        <Grid container justify="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Recipients:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container spacing={1} item xs={9}>
                            {emailData.tos.map((to, index) => (
                                <Grid item key={`${index}${to}`}>
                                    <Chip
                                        label={to}
                                        onDelete={() => removeTo(to)}
                                        variant="outlined"
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                )}
                {emailData.selectedEmails.hostedfailedcontact && (
                    <>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid container justify="flex-end" item xs={3}>
                                <Grid item>
                                    <Typography>
                                        <b>Contact Name:</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    error={!!emailData.contactnameError}
                                    label={emailData.contactnameError}
                                    value={emailData.contactname}
                                    onChange={(e) =>
                                        setEmailData({
                                            ...emailData,
                                            contactname: sanitize(
                                                e.target.value
                                            ),
                                            contactnameError: ''
                                        })
                                    }
                                    variant="outlined"
                                    size="small"
                                    placeholder="John Doe"
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid container justify="flex-end" item xs={3}>
                                <Grid item>
                                    <Typography>
                                        <b>Deadline Date:</b>
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={9}>
                                <TextField
                                    error={!!emailData.deadlinedateError}
                                    label={emailData.deadlinedateError}
                                    value={emailData.deadlinedate}
                                    onChange={(e) =>
                                        setEmailData({
                                            ...emailData,
                                            deadlinedate: sanitize(
                                                e.target.value
                                            ),
                                            deadlinedateError: ''
                                        })
                                    }
                                    variant="outlined"
                                    size="small"
                                    placeholder="May 20th, 2021"
                                />
                            </Grid>
                        </Grid>
                    </>
                )}
            </Grid>
        </Grid>
    )
}

export default HostedFields
