import React, { useEffect, useContext, useState } from 'react'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CircularProgress from '@material-ui/core/CircularProgress'
import EmailConfirm from '../pages/EmailConfirm'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import Grid from '@material-ui/core/Grid'
import HostedSelect from '../pages/HostedSelect'
import HostedFields from '../pages/HostedFields'
import {
    EmailDataContext,
    initialEmailData
} from '../contexts/EmailDataContext'
import Modal from '../components/Modal'
import SectionStepper from './SectionStepper'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { useTheme } from '@material-ui/core/styles'
import { validateSections } from '../shared/validate'
import { useAPIClient } from 'react-toolbox/APIClient'
import { useMutation } from 'react-query'

// Declaring these non-state items inside component with every re-render is not ideal
const steps = ['Select Emails', 'Enter Information', 'Confirm & Send']
const initialModalData = {
    show: false,
    cancelDisabled: true,
    title: 'Send Email(s)',
    h6: 'Communicating with API',
    graphic: <CircularProgress style={{ height: 128, width: 128 }} />,
    p: 'Sending data, please wait.'
}

const Sections = () => {
    // State / Context
    const theme = useTheme()
    const [emailData, setEmailData] = useContext(EmailDataContext)
    const [activeStep, setActiveStep] = useState(0)
    const [showSubmit, setShowSubmit] = useState(false)
    const [modalData, setModalData] = useState(initialModalData)
    // Sections
    const stepContent = [
        <HostedSelect key="SectionOne" />,
        <HostedFields key="SectionTwo" />,
        <EmailConfirm key="SectionThree" />
    ]
    // API Interaction
    const api = useAPIClient()
    // Send Email Request
    const [sendEmailMutation] = useMutation(api.email.send)
    const [bulkSendEmailMutation] = useMutation(api.email.sendBulk)

    // Handlers
    const handleStep = (next) => {
        if (next) {
            // Check to make sure we're not on the final section before continuing.
            if (activeStep < steps.length - 1) {
                // Ensure valid entries before proceeding.
                if (validateSections(activeStep, emailData, setEmailData)) {
                    setActiveStep(activeStep + 1)
                }
            }
        } else {
            if (activeStep > 0) {
                setActiveStep(activeStep - 1)
            }
        }
    }
    const handleSend = async () => {
        // Display Modal to user
        setModalData({ ...modalData, show: true })
        // Any failures will invalidate this
        let success = true
        if (emailData.recipients?.length > 1) {
            // We're trying a bulk send
            for (const [key, value] of Object.entries(
                emailData.selectedEmails
            )) {
                if (value) {
                    await bulkSendEmailMutation(
                        {
                            email: key,
                            recipients: repackageData(emailData.recipients) // Recipients should be []{cid,[]tos,contactname,deadlinedate} here.
                        },
                        {
                            // eslint-disable-next-line no-loop-func
                            onError: (error) => {
                                console.log(error)
                                success = false
                                setModalData({
                                    ...modalData,
                                    show: true,
                                    cancelDisabled: false,
                                    h6: 'An Error Occurred',
                                    graphic: (
                                        <ErrorOutlineIcon
                                            style={{ height: 128, width: 128 }}
                                        />
                                    ),
                                    p: error.error.message
                                })
                            }
                        }
                    )
                }
            }
        } else {
            // Fire request for each selected email
            for (const [key, value] of Object.entries(
                emailData.selectedEmails
            )) {
                if (value) {
                    await sendEmailMutation(
                        {
                            cid: Number(emailData.cid),
                            tos: emailData.tos,
                            email: key,
                            contactname: emailData.contactname,
                            deadlinedate: emailData.deadlinedate
                        },
                        {
                            // eslint-disable-next-line no-loop-func
                            onError: (error) => {
                                console.log(error)
                                success = false
                                setModalData({
                                    ...modalData,
                                    show: true,
                                    cancelDisabled: false,
                                    h6: 'An Error Occurred',
                                    graphic: (
                                        <ErrorOutlineIcon
                                            style={{ height: 128, width: 128 }}
                                        />
                                    ),
                                    p: error.error.message
                                })
                            }
                        }
                    )
                    // Braek out of loop on failure
                    if (!success) {
                        break
                    }
                }
            }
        }
        if (success) {
            // Adjust modal
            setModalData({
                ...modalData,
                show: true,
                cancelDisabled: false,
                h6: 'Success',
                graphic: (
                    <CheckCircleOutlineIcon
                        style={{ height: 128, width: 128 }}
                    />
                ),
                p: 'All selected emails have been sent successfully.'
            })
        }
    }
    const handleClose = () => {
        // If success, reset data and bring us back to the start
        if (modalData.h6 === 'Success') {
            setEmailData({
                ...initialEmailData,
                // When resetting the data context with initialEmailData, the "tos" array retains the value of its 0 index.
                // This leaves the first email recipient from the last successful send when the user gets to Section 2. No beuno.
                // I believe this is due to how Javascript references arrays. https://javascript.info/array#internals
                // Setting "tos" to a fresh array here solves this problem and works as intended originally.
                tos: []
            })
            setActiveStep(0)
        }
        // Close and Reset Modal
        setModalData(initialModalData)
    }

    // Effects
    useEffect(() => {
        // Ensure activeStep stays within bounds
        if (activeStep > steps.length - 1) {
            setActiveStep(steps.length - 1)
        }
        if (activeStep < 0) {
            setActiveStep(0)
        }
        // Determine if it is the final section to show submit button.
        if (activeStep === steps.length - 1) {
            setShowSubmit(true)
        } else {
            setShowSubmit(false)
        }
        // I don't want this to fire when setActiveStep is fired, that would cause endless loop.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeStep, steps])
    return (
        <Grid container item xs={12}>
            <Grid
                container
                justify="center"
                style={{ padding: theme.spacing(3) }}
            >
                <Grid container justify="center" item xs={12}>
                    <Grid item xs={12}>
                        <SectionStepper
                            steps={steps}
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h5">{steps[activeStep]}</Typography>
                    <br />
                    {stepContent[activeStep]}
                </Grid>
                <Grid item />
            </Grid>
            <Grid justify="center" container item xs={12} alignItems="center">
                <Grid item xs />
                {activeStep > 0 && (
                    <Grid item xs>
                        <center>
                            <Button
                                onClick={() => handleStep(false)}
                                variant="contained"
                            >
                                <b>Back</b>
                            </Button>
                        </center>
                    </Grid>
                )}
                <Grid item xs>
                    <center>
                        {showSubmit ? (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSend()}
                            >
                                <b>Send!</b>
                            </Button>
                        ) : (
                            <Button
                                color="secondary"
                                onClick={() => handleStep(true)}
                                variant="contained"
                            >
                                <b>Next</b>
                            </Button>
                        )}
                    </center>
                </Grid>
                <Grid item xs />
                <br />
                <br />
                <br />
            </Grid>
            {modalData.show && (
                <Modal
                    open={modalData.show}
                    title={modalData.title}
                    singleButton={true}
                    actionCancelDisabled={modalData.cancelDisabled}
                    actionCancel={() => handleClose()}
                    textCancel="Close"
                >
                    <Grid container>
                        <Grid container justify="center" item xs={12}>
                            <Grid item>
                                <Typography variant="h6">
                                    {modalData.h6}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container justify="center" item xs={12}>
                            <Grid item>{modalData.graphic}</Grid>
                        </Grid>
                        <Grid container justify="center" item xs={12}>
                            <Grid item>{modalData.p}</Grid>
                        </Grid>
                    </Grid>
                </Modal>
            )}
        </Grid>
    )
}

// repackageData takes the []{cid,email} and outputs []{cid,[]email}
function repackageData(input) {
    return input.reduce((acc, { cid, email }) => {
        // Find if an entry with the same 'cid' already exists
        let cidInt = parseInt(cid)
        let entry = acc.find((item) => item.cid === cidInt)

        if (!entry) {
            // If not found, create a new entry with the 'cid' and an empty 'tos' array
            entry = { cid: cidInt, tos: [] }
            acc.push(entry)
        }

        // Add the email to the 'tos' array of the found or newly created entry
        entry.tos.push(email)

        return acc
    }, [])
}

export default Sections
