import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import { EmailDataContext } from '../contexts/EmailDataContext'
import Typography from '@material-ui/core/Typography'

const EmailConfirm = () => {
    // State / Context
    const [emailData] = useContext(EmailDataContext)
    // If we have something in emailData.recipients, we want to use bulk send.
    if (emailData?.recipients.length > 0) {
        return (
            <Grid container justify="center" spacing={1} alignContent="center">
                <Grid container item xs={12} spacing={3}>
                    <Grid container justify="flex-end" item xs={3}>
                        <Grid item>
                            <Typography>
                                <b>Selected Email(s):</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={9} spacing={1}>
                        {emailData.selectedEmails &&
                            Object.keys(emailData.selectedEmails).map(
                                // eslint-disable-next-line array-callback-return
                                (key, index) => {
                                    if (emailData.selectedEmails[key]) {
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                key={`gridselemail${index}`}
                                            >
                                                <Typography>
                                                    {
                                                        emailData.hosted[key]
                                                            .title
                                                    }
                                                </Typography>
                                            </Grid>
                                        )
                                    }
                                }
                            )}
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <Grid container justify="flex-end" item xs={3}>
                        <Grid item>
                            <Typography>
                                <b>Recipient(s):</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container item xs={9} spacing={1}>
                        <div style={styles.scrollBox}>
                            <table>
                                <tr>
                                    <td>
                                        <b>CID</b>
                                    </td>
                                    <td>
                                        <b>Email</b>
                                    </td>
                                </tr>
                                {emailData.recipients.map((datum, i) => (
                                    <tr key={i}>
                                        <td>{datum.cid}</td>
                                        <td>{datum.email}</td>
                                    </tr>
                                ))}
                            </table>
                        </div>
                        <Grid item xs={12}>
                            <Typography>
                                <b>
                                    This will send this email to{' '}
                                    {emailData.recipients.length} dealers
                                </b>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid container justify="center" spacing={1} alignContent="center">
            <Grid container item xs={12} spacing={3}>
                <Grid container justify="flex-end" item xs={3}>
                    <Grid item>
                        <Typography>
                            <b>Selected Email(s):</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={9} spacing={1}>
                    {emailData.selectedEmails &&
                        Object.keys(emailData.selectedEmails).map(
                            // eslint-disable-next-line array-callback-return
                            (key, index) => {
                                if (emailData.selectedEmails[key]) {
                                    return (
                                        <Grid
                                            item
                                            xs={12}
                                            key={`gridselemail${index}`}
                                        >
                                            <Typography>
                                                {emailData.hosted[key].title}
                                            </Typography>
                                        </Grid>
                                    )
                                }
                            }
                        )}
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid container justify="flex-end" item xs={3}>
                    <Grid item>
                        <Typography>
                            <b>CID:</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={9}>
                    <Grid item>
                        <Typography>{emailData.cid}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item xs={12} spacing={3}>
                <Grid container justify="flex-end" item xs={3}>
                    <Grid item>
                        <Typography>
                            <b>Recipient(s):</b>
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container item xs={9} spacing={1}>
                    {emailData.tos &&
                        emailData.tos.length > 0 &&
                        emailData.tos.map((to, index) => (
                            <Grid item xs={12} key={`${index}${to}`}>
                                <Typography>{to}</Typography>
                            </Grid>
                        ))}
                </Grid>
            </Grid>
            {emailData.selectedEmails.hostedfailedcontact && (
                <>
                    <Grid container item xs={12} spacing={3}>
                        <Grid container justify="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Contact Name:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid item>
                                <Typography>{emailData.contactname}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} spacing={3}>
                        <Grid container justify="flex-end" item xs={3}>
                            <Grid item>
                                <Typography>
                                    <b>Deadline Date:</b>
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item xs={9}>
                            <Grid item>
                                <Typography>
                                    {emailData.deadlinedate}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
        </Grid>
    )
}
const styles = {
    scrollBox: {
        maxHeight: '300px', // Set your desired height
        overflowY: 'auto',
        border: '1px solid #ccc',
        padding: '10px',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
        marginTop: '10px' // Optional: add some space between the input and table
    }
}
export default EmailConfirm
