import React, { useState, createContext } from 'react'
import PropTypes from 'prop-types'

export const EmailDataContext = createContext()

// Allow easy resetting of our context
export const initialEmailData = {
    recipients: [], // recipients is used instead when bulk sending
    // Email address to send selected email(s) to
    tos: [],
    tosError: '',
    // CID is used to post Gnotes in CRM
    cid: '',
    cidError: '',
    // Hosted-specific emails
    hosted: {
        overview: {
            title: 'Hosted Frazer Overview',
            description:
                "Send this email to any dealer interested in Hosted. Provides a comprehensive overview of what Hosted is, as well as a button to Sign Up to get started. When we receive their information, we'll build a server and contact them once completed to move to Hosted."
            // Any dynamic data fields can go here
        },
        macsetup: {
            title: 'MyFrazer Mac Setup',
            description:
                'Send this email to any "Hosted 1" Dealer who wishes to use Hosted Frazer on their Mac. It details the process of installing and configuring the Microsoft Remote Desktop app with step-by-step instructions.'
        },
        hostedfrazermacsetup: {
            title: 'Hosted Frazer Mac Setup',
            description:
                'Send this email to any "Hosted 2" Dealer who wishes to use Hosted Frazer on their Mac. It details the process of installing and configuring the Microsoft Remote Desktop app with step-by-step instructions.'
        },
        hostedfrazeriossetup: {
            title: 'Hosted Frazer iOS Setup',
            description:
                'Send this email to any Hosted 2 Dealer who wishes to use Hosted Frazer on their iOS device (iPhone, iPad.) This email details the process of installing and configuring the Microsoft Remote Desktop app with step-by-step instructions.'
        },
        hostedfailedcontact: {
            title: 'Hosted Frazer Failed to Contact',
            description:
                'Typically used by the Hosted Team, use this whenever you failed to contact a dealer regarding their Hosted Frazer Request.'
        },
        hostedfrazerwindowsmfasetup: {
            title: 'Hosted Frazer and MFA Setup: Windows',
            description:
                'Send this welcome email to new Hosted Frazer dealers who will be using Hosted Frazer on a Windows PC. It details the process of registering for MFA, as well as installing and configuring the Hosted Frazer Client.'
        },
        selectError: ''
    }
    // In the future there will be more emails added to this. Payments had about 23 templates with different dynamic data they could use an easy way to send.
}

/** Provider for the email data context */
export const EmailDataProvider = ({ children }) => {
    // Holds data about emails, as well as data that needs to be passed to backend
    const [emailData, setEmailData] = useState(initialEmailData)
    return (
        <EmailDataContext.Provider value={[emailData, setEmailData]}>
            {children}
        </EmailDataContext.Provider>
    )
}

EmailDataProvider.propTypes = {
    children: PropTypes.node
}
