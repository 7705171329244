import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import PropTypes from 'prop-types'

const Modal = ({
    children,
    open,
    title,
    actionApply,
    actionCancel,
    textCancel,
    textApply,
    maxWidth,
    actionApplyDisabled = false,
    actionCancelDisabled = false,
    singleButton = false
}) => {
    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth ? maxWidth : 'xs'}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Button
                    onClick={() => actionCancel()}
                    disabled={actionCancelDisabled}
                >
                    {textCancel ? textCancel : 'Cancel'}
                </Button>
                {!singleButton && (
                    <Button
                        onClick={() => actionApply()}
                        disabled={actionApplyDisabled}
                    >
                        {textApply ? textApply : 'Apply'}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}

export default Modal

Modal.propTypes = {
    children: PropTypes.node,
    /** Whether or not the modal is shown */
    open: PropTypes.bool.isRequired,
    /** Title Text */
    title: PropTypes.string,
    /** Function for closing modal and applying changes */
    actionApply: PropTypes.func,
    /** Function for closing modal without applying changes */
    actionCancel: PropTypes.func,
    /** Text of the Apply button (Defaults to "Apply") */
    textApply: PropTypes.string,
    /** Text of the Cancel button (Defaults to "Cancel") */
    textCancel: PropTypes.string,
    /** Sets the Max Width to the max value of a specified breakpoint ("xs","sm","md","lg","xl") Defaults to "xs" */
    maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    /** Useful for if a condition is required before enabling "Apply" (Defaults to false) */
    actionApplyDisabled: PropTypes.bool,
    /** Useful for if a condition is required before enabling "Cancel" (Defaults to false) */
    actionCancelDisabled: PropTypes.bool,
    /** Useful for when only a Cancel or Dismiss button is needed. Used for relaying information to user with no changes. (Defaults to false) */
    singleButton: PropTypes.bool
}
