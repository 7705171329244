import React, { useState, useContext, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { LoginContext } from '../contexts/LoginContext'
import * as Msal from 'msal'
import Typography from '@material-ui/core/Typography'
import MainWindow from '../components/MainWindow'
import MSLoginButton from '../components/MSLoginButton'

// Microsoft Authentication Library setup
// https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-core
const msalConfig = {
    auth: {
        // Application (client) ID
        clientId: process.env.REACT_APP_MS_CLIENT_ID,
        // Login URL for our organization
        authority: process.env.REACT_APP_MS_TENANT_URL,
        validateAuthority: true
    }
}
const loginRequest = {
    scopes: ['user.read']
}
const msalInstance = new Msal.UserAgentApplication(msalConfig)

/**
 * Login / landing page when the user visits the site.
 */
const Login = () => {
    // State / Context
    const [loginInfo, setLoginInfo] = useContext(LoginContext)
    const [message, setMessage] = useState(
        'Attempting to sign you in automatically, one moment...'
    )
    const loginHandler = () => {
        msalInstance
            .loginPopup(loginRequest)
            .then((response) => {
                // Ensure we have a token and expiration
                const idtoken = response?.idToken?.rawIdToken
                const exp = response?.idToken?.expiration
                // Ensure token and expiration are not empty
                if (!idtoken || !exp) {
                    // Token/exp is either empty string, null, or undefined, log it and warn user
                    console.log('Failed to get token or expiration!')
                    setMessage('An error occurred while attempting to sign in.')
                    return
                }
                // Update our login state with token and expiration
                setLoginInfo({
                    ...loginInfo,
                    token: idtoken,
                    expires: exp
                })
            })
            .catch((error) => {
                // Log it, and warn user
                console.log('Error authenticating with Microsoft: ', error)
                setMessage('An error occurred while attempting to sign in.')
            })
    }
    // Attempt to sign in user upon mounting of component
    useEffect(() => {
        loginHandler()
        // Adding handler function as dep would cause infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <MainWindow>
            <Grid container item xs={12} alignContent="center" justify="center">
                <Grid item>
                    <br />
                    <Typography variant="h6">{message}</Typography>
                    <br />
                </Grid>
            </Grid>
            <Grid container item xs={12} alignContent="center" justify="center">
                <Grid item>
                    <Typography>
                        You can also click below to log in manually if nothing
                        happens. Ensure popups are allowed for this page.
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                item
                justify="center"
                xs={12}
                style={{ height: '200px' }}
            >
                <Grid item style={{ paddingTop: '64px' }}>
                    <MSLoginButton onClick={loginHandler} />
                </Grid>
            </Grid>
        </MainWindow>
    )
}

export default Login
