// This will be eventually removed when formatters are added to react-toolbox

/**
 * Takes any input and converts/casts it to a string.
 * @param {*} val Input to be converted/casted to a string
 */
const convertToString = (val) => {
    return val + ''
}

/**
 * Scrub a string for invalid characters, and ensure it does not exceed a maximum length.
 * Typical data entry from a user does not require any special characters (with the exception of passwords, which this function should not be used for)
 * @param {string} str The string to sanitize
 * @param {object} [opts] Optional options object to customize behavior
 * @property {number} [maxLength] Specify a max length to the string, will remove any excess.
 * @property {boolean} [toUpper=false] If true, convers str to uppercase.
 * @property {boolean} [strictLetters] If true, trims anything that is not a letter character.
 * @returns {string} Sanitized string.
 */
const sanitize = (str, { maxLength, toUpper, strictLetters } = {}) => {
    // Make sure it's a string
    str = convertToString(str)
    // If it is strictly letters, remove anything that is not a letter character
    if (strictLetters === true) {
        str = str.replace(
            // eslint-disable-next-line no-useless-escape
            /[^a-z]/gim,
            ''
        )
    } else {
        // Scrub string of unwanted characters but with support for multiple languages. ('\pL' does not work in Javascript)
        // '.,_-' are allowed for users to enter names such as "Phillip Billy-Bob, Sr.", etc
        str = str.replace(
            // eslint-disable-next-line no-useless-escape
            /[^a-záàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ0-9@ \.,_-]/gim,
            ''
        )
    }
    // Ensure it does not exceed maxlength
    if (maxLength !== undefined) {
        str = str.substring(0, maxLength)
    }
    // Make it entirely uppercase if required
    if (toUpper === true) {
        str = str.toUpperCase(str)
    }
    // Return the clean string
    return str
}

/**
 * Formats input to only contain numbers, and optionally specify a minimum and maximum value.
 * Intended use-case is for input fields, thus why it returns string.
 * @param {string|number} num The string of numbers to format
 * @param {object} opts
 * @property {boolean} [forceInt] Whether to force integer or allow float.
 * @property {number} [minVal] Minimum value allowed
 * @property {number} [maxVal] Maximum value allowed
 * @returns {string} Sanitized value in string format
 */
const sanitizeNumber = (num, { forceInt, minVal, maxVal } = {}) => {
    // Convert it to a string if need be
    let str = convertToString(num)
    // Declare a variable to store the parsed number from string
    let val = 0
    // Strip any characters that are not a number (allow decimal if needed)
    if (forceInt !== false) {
        str = str.replace(/\D/g, '')
        val = parseInt(str, 10)
        if (isNaN(val)) {
            return (val = '')
        }
    } else {
        // eslint-disable-next-line no-useless-escape
        str = str.replace(/[^0-9\.]/g, '')
        val = parseFloat(str)
        if (isNaN(val)) {
            return ''
        }
    }
    // Minimum limit (optional)
    if (minVal !== undefined) {
        if (val < minVal) {
            val = minVal
        }
    }
    // Maximum limit (optional)
    if (maxVal !== undefined) {
        if (val > maxVal) {
            val = maxVal
        }
    }
    // Convert back to string and return it
    return convertToString(val)
}

export { sanitize, sanitizeNumber }
