import React from 'react'
import { LoginProvider } from './contexts/LoginContext'
import WrappedApp from './components/WrappedApp'

function App() {
    return (
        <div className="App">
            <LoginProvider>
                <WrappedApp />
            </LoginProvider>
        </div>
    )
}

export default App
