/**
 * Checks a string if it is in the form of an email address
 * @param {string} val Email address to validate
 */
const validateEmailFormat = (val) => {
    // Lifted from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#Validation
    // eslint-disable-next-line no-useless-escape
    const regex = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
    return regex.test(val)
}

/**
 * Ensures the data entered into specified section is valid (in an expected format/pattern)
 * @param {number} step The current step or section to validate
 * @param {Object} data The data to validate (In this case a copy of emailData context)
 * @param {function} setData Function to update data (In this case we're updating error messages)
 * @param {string} type What type of emails are we sending? Currently only Hosted emails
 */
const validateSections = (step, data, setData, type = 'hosted') => {
    // If anything fails, set ready to false
    let ready = true
    // Section 1 (Email Selection), ensure at least 1 email is selected.
    if (step === 0) {
        // Check if it exists
        if (!data.selectedEmails) {
            ready = false
            data[type].selectError =
                'You must have at least one email selected.'
        } else {
            // Iterate through object and ensure at least 1 is true
            let good = false
            // [key, value] in this case I only care about the value
            for (const [, selected] of Object.entries(data.selectedEmails)) {
                if (selected) {
                    good = true
                }
            }
            if (!good) {
                ready = false
                data[type].selectError =
                    'You must have at least one email selected.'
            }
        }
    }
    // Section 2 (Dynamic Data Entry)
    if (step === 1) {
        if (data.recipients.length > 0) {
            // We already validated on the hostedfields screen, so we'll just say we're ready here.
            ready = true
        } else {
            // CID
            if (!data.cid || data.cid === '') {
                ready = false
                data.cidError = 'Missing CID.'
            }
            // Tos
            if (!data.tos || data.tos.length <= 0) {
                ready = false
                data.tosError = 'At least one recipient is required.'
            } else {
                // Tos format (this is also checked when the user first tries to enter an email)
                for (let i = 0; i < data.tos.length; i++) {
                    if (!validateEmailFormat(data.tos[i])) {
                        ready = false
                        data.tosError =
                            'Invalid email, please ensure email addresses are valid.'
                    }
                }
            }
            // Contact Name (Only if hostedfailedcontact is selected)
            if (data.selectedEmails.hostedfailedcontact && !data.contactname) {
                ready = false
                data.contactnameError = 'Missing Contact Name'
            }
            // Deadline Date (Only if hostedfailedcontact is selected)
            if (data.selectedEmails.hostedfailedcontact && !data.deadlinedate) {
                ready = false
                data.deadlinedateError = 'Missing Deadline Date'
            }
            // Additional fields would be validated as required, depending on type and selected emails.
        }
    }
    // Section 3 (Confirm) No checks needed here at this time.

    // Update data and return whether it is ready or not to proceed.
    setData({ ...data })
    return ready
}

export { validateSections, validateEmailFormat }
