import React from 'react'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import { useTheme } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import frazerLogo from '../images/FrazerLogo_DarkBackground_Transparent_forweb.png'

const logo = (
    <img
        src={frazerLogo}
        alt="Hosted Frazer Logo"
        style={{
            width: '100%',
            paddingTop: '24px',
            paddingBottom: '24px'
        }}
    />
)

const MainWindow = ({ children }) => {
    const theme = useTheme()
    return (
        <Grid container item direction="row" justify="center" xs={12}>
            <Grid item xs={12} md={10} lg={7} xl={5} zeroMinWidth>
                <main style={{ padding: theme.spacing(0) }}>
                    <Paper elevation={5}>
                        <Grid
                            container
                            item
                            xs={12}
                            justify="center"
                            style={{
                                background: theme.palette.primary.dark
                            }}
                        >
                            <Grid item xs={4}>
                                {logo}
                            </Grid>
                        </Grid>
                        <Grid container item xs={12}>
                            {children}
                        </Grid>
                    </Paper>
                </main>
            </Grid>
        </Grid>
    )
}

export default MainWindow

MainWindow.propTypes = {
    children: PropTypes.node
}
